import {ucptScrollTo} from './_utilities';

$(document).ready(function(){
    $('.ucpt-manufacturers-list__toggle').click(function(e) {
        e.preventDefault();
        $(this).toggleClass('open');
        if ($(this).hasClass('open')) {
            var toggleText = $(this).data('text-hide');
        }
        else {
            var toggleText = $(this).data('text-show');
        }
        $(this).parents('.ucpt-manufacturers-list').find('.ucpt-manufacturers-list__item--hidden').toggle();
        $(this).find('> b').text(toggleText);
        $(this).find('> i').toggleClass('fa-angle-up').toggleClass('fa-angle-down');

        ucptScrollTo($(this).attr('href'));
    });
});