// Import Bootstrap select utilities
$.fn.selectpicker.Constructor.BootstrapVersion = '4';

// Import components
import "./components/_utilities";
import "./components/_glossary";
import "./components/_placeholder";
import "./components/_quickadd";
import "./components/_search-block";
import "./components/_manufacturers-list";

// Import pages
import "./pages/product-compatibilities";
import "./pages/garage";
import "./pages/model";


// == Boostrap select application
$(document).ready(function(){
    initBootstrapSelect();

    $('body').on('ukoopartsRefresh', function() {
        initBootstrapSelect();
    });

    
    // hide url parameter used to redirect customer after he unselect his active engine
    let queryString = window.location.search;
    if (queryString) {
        let urlParams = new URLSearchParams(queryString);
        if (urlParams.has('needRedirect')) {
            urlParams.delete('needRedirect');
            let newURL = window.location.href.split('?')[0];
            let newParams = urlParams.toString();
            newParams = !!newParams ? '?' + newParams : '';
            window.history.replaceState(null, null, newURL + newParams);
        }
    }
});

function initBootstrapSelect()
{
    $('select.ucpt-bselect:not(.ucpt-bselect--loaded)').selectpicker().addClass('ucpt-bselect--loaded').on('loaded.bs.select', function(){
        var selectTitle = $(this).attr('title');
        var $selectParent = $(this).parents('.bootstrap-select');
        if (selectTitle.length) {
            $selectParent.append('<fieldset><legend><span>'+selectTitle+'</span></legend></fieldset>');
            $selectParent.append('<div class="ucpt-bselect__placeholder">'+selectTitle+'</div>');
            $selectParent.addClass('has--placeholder');
        }
        if ($selectParent.find('.bs-searchbox').length) {
            $selectParent.addClass('has--livesearch');
        }

        $('select.ucpt-search__select:not(.ucpt-search__select--force)').selectpicker('val',' ');
        $('select.ucpt-search__select:not(.ucpt-search__select--force)').selectpicker('refresh');
    });
}