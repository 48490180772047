$(document).ready(function(){
    $('.ucpt-smoothscroll').click(function (e) {
        e.preventDefault();
        var target = $(this).attr('href');
        var targetSilo = $(this).attr('data-siloing');
        if (target) {
            ucptScrollTo(target);
        }
        else if (targetSilo) {
            ucptScrollTo(targetSilo);
        }
    });
});

export function ucptScrollTo(target) {
    if (window.matchMedia("(min-width:0px) and (max-width: 1199px)").matches) {
        // Je teste sur la résolution/périphérique sur lequel on se trouve pour rajouter un décalage du scroll équivalent à la hauteur du header fixe
        $("html, body").animate({scrollTop: $(target).offset().top - 58 - 10}, 500);
    } else {
        // Si on est sur Desktop
        $("html, body").animate({scrollTop: $(target).offset().top - 10}, 500);
    }
}