$(document).ready(function () {
    // Scroll management
    $(document).on('click', '.ucpt-glossary-list__link', function () {
        var target = $(this).data('target');
        if (window.matchMedia("(min-width:1024px)").matches) {
            ucpt_scrolldesktop(target);
        } else {
            ucpt_scrollmobile(target);
        }
    });

    // Search management
    $('.ucpt-input-search__select.ucpt-input-search__select--default').on('change', function(){
        selectVal = $(this).val();
        $glossary = $('.ucpt-glossary');
        $glossaryItem = $('.ucpt-glossary__item');
        $glossaryItemChild = $('.ucpt-glossary__item-child');
        $glossaryItemChildSelected = $glossary.find('.ucpt-glossary__item-child[data-id="'+selectVal+'"]');

        if (selectVal) {
            $glossary.find($glossaryItem).hide();
            $glossary.find($glossaryItemChild).hide();

            $glossary.find($glossaryItemChildSelected).show().parents($glossaryItem).show();
        }
        else {
            $glossary.find($glossaryItem).show();
            $glossary.find($glossaryItemChild).show();
        }
    });
    $('.ucpt-input-search__select.ucpt-input-search__select--redirect').on('change', function(){
        selectVal = $(this).val();
        if (selectVal) {
            window.location.href = selectVal;
            $(this).attr('disabled', 'disabled').selectpicker('refresh');
            $(this).parents('.ucpt-input-search').after('<p style="transform: translateY(-100%);"><i class="text-primary small spinner-border" style="width: 1em; height: 1em; border-width: 0.2em;vertical-align: -0.1em;"></i> '+ucpt_input_search_txt_soon_redirected+'</p>');
        }
    });
});

function ucpt_scrollmobile(target) {
    // TODO[DEV] by ukoo_jordan [2022-11-02 16:39] -> Prévoir sélecteur dynamique en BO si header fixe
    var fixedHeaderMobileHeight = $('.header__fixed').outerHeight();
    if (typeof fixedHeaderMobileHeight === "undefined") {
        fixedHeaderMobileHeight = 0;
    }
    var offTopTargetMob = $(target).offset().top;
    offTopMob = offTopTargetMob - fixedHeaderMobileHeight - 10;
    $(window).scrollTop(offTopMob);
}

function ucpt_scrolldesktop(target) {
    // TODO[DEV] by ukoo_jordan [2022-11-02 16:39] -> Prévoir sélecteur dynamique en BO si header fixe
    var fixedHeaderDesktopHeight = $('#nav_wrapper').outerHeight();
    if (typeof fixedHeaderDesktopHeight === "undefined") {
        fixedHeaderDesktopHeight = 0;
    }
    var offTopTarget = $(target).offset().top;
    offTop = offTopTarget - fixedHeaderDesktopHeight - 10;
    $(window).scrollTop(offTop);
}