/**
 * Prestashop module : Ukooparts
 *
 * @author Ukoo <contact@ukoo.fr>
 * @copyright Ukoo
 * @license Tous droits réservés / Le droit d'auteur s'applique (All rights reserved / French copyright law applies)
 */

$(document).ready(function(){
    var $ucptSelect = $('#ucpt-select-years');
    var $ucptTooltip = $('.ucpt-tooltip');
    var $ucptModal = $('#ucpt-modal-years');
    var $ucptModalAdd = $('#ucpt-modal-years-add');
    var $ucptModalView = $('#ucpt-modal-years-view');

    $(document).ready(function () {
        // Select management
        $ucptTooltip.tooltip({
            template: '<div class="ucpt-tooltip__tooltip tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
            placement: 'top',
            title: $(this).attr('title')
        });

        $ucptSelect.on('change', function () {
            $ucptModal.modal('show');
        });
        // Add motorbike
        $ucptModalAdd.on('click', function () {
            $(this).parents($ucptModal).modal('hide');
            // else...
        });
        // View compatible parts
        $ucptModalView.on('click', function () {
            $(this).parents($ucptModal).modal('hide');
        });
    });
    
    var currentRequest = null;
    // categories by model child (years/version)
    $('#ucpt-select-years').on('change', function () {
        var modelId = $(this).val();
        if (modelId == '') {
            return;
        }

        currentRequest = $.ajax({
            type: 'GET',
            dataType: 'html',
            beforeSend: function () {
                if (currentRequest != null) {
                    currentRequest.abort();
                    currentRequest = null;
                }
                $('.js-enginemodel-categories').empty();
                $('.js-enginemodel-categories').empty();
            },
            url: '/module/ukooparts/models?ajax=true&debug=1&modelId=' + modelId,
            success: function (result) {
                var result = jQuery.parseJSON(result);
                $('.js-enginemodel-categories').html(result.cats);

                $('.js-enginemodel-img').attr({'src': result.model.image, 'alt':result.model.manufacturer.name + ' ' + result.model.displacement + ' ' + result.model.model + ' ' + result.model.yearStart});

                $('.js-enginemodel-startendyear').html(result.model.yearStart + '-' + result.model.yearEnd);
                $('.js-enginemodel-startyear').html(result.model.yearStart);

                $('.js-enginemodel-title').html(result.model.manufacturer.name + ' ' + result.model.displacement + ' ' + result.model.model + ' ' + result.model.yearStart);
                $('.js-enginemodel-description').html(result.model.description);
            }
        });

    });
    $('#ucpt-modal-years-add').on('click', function (e) {
        e.preventDefault();
        var modelId = $('#ucpt-select-years').val();

        if (!modelId) {
            // todo : display error message
            return false;
        }
        currentRequest = $.ajax({
            type: 'POST',
            dataType: 'json',
            beforeSend: function () {
                if (currentRequest != null) {
                    currentRequest.abort();
                    currentRequest = null;
                }
            },
            url: '/module/ukooparts/front?action=addToGarage&ajax=true&model=' + modelId,
            success: function (result) {
                var result = jQuery.parseJSON(result);
                // todo handle response
                $(this).parents('#ucpt-modal-years').modal('hide');
            }
        });
    });
});