import {ucptScrollTo} from './_utilities';

$(document).ready(function () {
    quickaddResponsiveBreakpoint();
    // Spécifique : Permet de rendre fixe la recherche à facette de façon indépendante, tout en rendant le quickaddfacets fixe également
    $(window).resize(function(){
        quickaddResponsiveBreakpoint();
    });

    $(document).on('click', '.ucpt-buttons__button--unselect-engine', function () {
        $.ajax({
            type: 'GET',
            dataType: 'json',
            url: '/module/ukooparts/front?action=unselectEngine&ajax=true',
            success: function (result) {
                if (result.success) {
                    let url = window.location.href.split('#');
                    let new_url = url[0];
                    let anchor = !!url[1] ? '#' + url[1] : '';
                    new_url = new_url.split('?')[0];

                    let urlParams = new URLSearchParams(window.location.search);
                    urlParams.append('needRedirect', '' + !!($('.ucpt-quickadd').data('engine-active')));
                    let newParams = urlParams.toString();
                    newParams = !!newParams ? '?' + newParams : '';

                    window.location.href = new_url + newParams + anchor;
                } else if (result.message) {
                    $('#ucpt-modal-quickadd .ucpt-buttons').before('<p class="alert alert-danger">result.message</p>');
                }
            }
        });
    });

    $(document).on('click', '.ucpt-compatibilities-table__link', function(e) {
        e.preventDefault();
        let idTab = $('#ucpt-compatibilities-table').parents('.tab-pane').attr('id');
        if (idTab) {
            $('.nav-link[href=#' + idTab + '], .tab-pane-toggle[href=#' + idTab + '-collapse]').click();
            ucptScrollTo('#product-tabs');
        }
    });

    // Check if facets is present, if not we add class on quickadd to deactivate border-radius
    if(!$('.ucpt-quickadd--facets').find('+ #_desktop_search_filters_wrapper').length) {
        $('.ucpt-quickadd--facets').addClass('ucpt-quickadd--fullradius');
    }
});

function quickaddResponsiveBreakpoint () {
    if (window.matchMedia("(min-width:1024px)").matches) {
        quickaddScrollFixed();
    }
    else {
        quickaddScrollUnfixed();
    }
}

function quickaddScrollFixed() {
    if ($('#_desktop_search_filters_wrapper').length && $('.ucpt-quickadd--facets').length) {
        var $quickaddfacets = $('.ucpt-quickadd--facets')
        var quickaddfacetsHeight = $quickaddfacets.outerHeight();

        $quickaddfacets.addClass('must-fixed').find('+ div#_desktop_search_filters_wrapper').css('top','calc('+quickaddfacetsHeight+'px + 29px)');
    }
}
function quickaddScrollUnfixed() {
    if ($('#_desktop_search_filters_wrapper').length && $('.ucpt-quickadd--facets').length) {
        var $quickaddfacets = $('.ucpt-quickadd--facets');
        $quickaddfacets.removeClass('must-fixed').find('+ div#_desktop_search_filters_wrapper').css('top','0');
    }
}