/**
 * Prestashop module : Ukooparts
 *
 * @author Ukoo <contact@ukoo.fr>
 * @copyright Ukoo
 * @license Tous droits réservés / Le droit d'auteur s'applique (All rights reserved / French copyright law applies)
 */

$(document).ready(function () {
    /* Ajax requests */
    var currentRequest = null;
    $('#ucpt-compatibilities-filters .ucpt-bselect').on('change', function () {
        var productId = $('#product_page_product_id').val();
        var manufacturerId = $('#ucpt-compatibilities-select-brand').val();
        var displacement = $('#ucpt-compatibilities-select-displacement').val();
        var modelName = $('#ucpt-compatibilities-select-model').val();
        var year = $('#ucpt-compatibilities-select-year').val();

        if (manufacturerId == null || manufacturerId === '' || manufacturerId === undefined) {
            manufacturerId = 0;
        }
        if (displacement == null || displacement === '' || displacement === undefined) {
            displacement = 0;
        }
        if (modelName == null || modelName === '' || modelName === undefined) {
            modelName = '';
        }
        if (year == null || year === '' || year === undefined) {
            year = 0;
        }

        currentRequest = $.ajax({
            type: 'GET',
            dataType: 'html',
            beforeSend: function () {
                if (currentRequest != null) {
                    currentRequest.abort();
                    currentRequest = null;
                }
                $('#ucpt-compatibilities-table').addClass('ucpt-spinner--is-spining').prepend('<div class="ucpt-spinner"></div>');
            },
            url: '/module/ukooparts/productcompatibility?ajax=true&productId=' + productId + '&manufacturerId=' + manufacturerId + '&modelName=' + modelName + '&displacement=' + displacement + '&year=' + year + '&page=1',
            success: function (result) {
                var result = jQuery.parseJSON(result);
                $('#ucpt-compatibilities-results').html(result.data);
                $('#ucpt-compatibilities-pagination').html(result.pagination);

                $('#ucpt-compatibilities-table').removeClass('ucpt-spinner--is-spining').find('.ucpt-spinner').remove();
            }
        });

    });
    $('#ucpt-compatibilities-pagination').on('click', 'a', function (e) {
        e.preventDefault();
        var page = $(this).attr('page');
        var productId = $('#product_page_product_id').val();
        var manufacturerId = $('#ucpt-compatibilities-select-brand').val();
        var displacement = $('#ucpt-compatibilities-select-displacement').val();
        var modelName = $('#ucpt-compatibilities-select-model').val();
        var year = $('#ucpt-compatibilities-select-year').val();

        if (manufacturerId == null || manufacturerId === '' || manufacturerId === undefined) {
            manufacturerId = 0;
        }
        if (displacement == null || displacement === '' || displacement === undefined) {
            displacement = 0;
        }
        if (modelName == null || modelName === '' || modelName === undefined) {
            modelName = '';
        }
        if (year == null || year === '' || year === undefined) {
            year = 0;
        }

        currentRequest = $.ajax({
            type: 'GET',
            dataType: 'html',
            beforeSend: function () {
                if (currentRequest != null) {
                    currentRequest.abort();
                    currentRequest = null;
                }
                $('#ucpt-compatibilities-table').addClass('is--loading');
            },
            url: '/module/ukooparts/productcompatibility?ajax=true&productId=' + productId + '&manufacturerId=' + manufacturerId + '&modelName=' + modelName + '&displacement=' + displacement + '&year=' + year + '&page=' + page,
            success: function (result) {
                var result = jQuery.parseJSON(result);
                $('#ucpt-compatibilities-results').html(result.data);
                $('#ucpt-compatibilities-pagination').html(result.pagination);

                $('#ucpt-compatibilities-table').removeClass('is--loading');
            }
        });
    });
});