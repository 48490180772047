$(document).ready(function() {
    // == Ajax request management
    var currentRequest = null;

    $(document).on('change', 'select.ucpt-search__select:not(.ucpt-search__select--model):not(.ucpt-search__select--reference)', function() {
        $(this).parents('.ucpt-search').addClass('ucpt-spinner--is-spining');
    });

    /* ajax script for getting  Manufacturer data*/
    $(document).on('change', 'select.ucpt-search__select--type', function () {
        var $contextParent = $(this).parents('.ucpt-search')
        $contextParent.find('select.ucpt-search__select--manufacturer,select.ucpt-search__select--year,select.ucpt-search__select--model').html('').prop('disabled', true).selectpicker('refresh');

        var typeID = $(this).val();
        if (typeID) {
            currentRequest = $.ajax({
                type: 'GET',
                dataType: 'json',
                beforeSend: function () {
                    if (currentRequest != null) {
                        currentRequest.abort();
                        currentRequest = null;
                    }
                },
                url: '/module/ukooparts/front?action=getBrand&ajax=true&type=' + typeID,
                success: function (result) {

                    $contextParent.find('select.ucpt-search__select--manufacturer').empty();
                    var options_highlight = '<optgroup label="' + top_brands + '">';
                    var options_normal = '<optgroup label="' + our_other_brands + '">';
                    $.each(result.highlight, function (k, v) {
                        options_highlight += '<option value="' + v.id + '">' + v.name + '</option>'
                    });
                    $.each(result.normal, function (k, v) {
                        options_normal += '<option value="' + v.id + '">' + v.name + '</option>'
                    });
                    options_highlight += '</optgroup>';
                    options_normal += '</optgroup>';

                    $contextParent.find('select.ucpt-search__select--manufacturer').append(options_highlight, options_normal);
                    $contextParent.find('select.ucpt-search__select--manufacturer').prop('disabled', false).selectpicker('refresh');

                    $contextParent.removeClass('ucpt-spinner--is-spining');
                }
            });
        }
    });
    /* ajax script to get Reference data */
    $(document).on('change', 'select.ucpt-search__select--manufacturer', function () {
        var $contextParent = $(this).parents('.ucpt-search');
        $contextParent.find('select.ucpt-search__select--reference').html('').prop('disabled', true).selectpicker('refresh');
        var typeID = $contextParent.find('select.ucpt-search__select--type').val();
        var marqueID = $(this).val();
        if (marqueID) {
            currentRequest = $.ajax({
                type: 'GET',
                dataType: 'json',
                beforeSend: function () {
                    if (currentRequest != null) {
                        currentRequest.abort();
                        currentRequest = null;
                    }
                },
                url: '/module/ukooparts/front?action=getModels&ajax=true&type=' + typeID + '&brand=' + marqueID,
                success: function (result) {
                    $contextParent.find('select.ucpt-search__select--reference').empty();
                    $.each(result, function (displacement, models) {
                        var displacement_options = '';
                        $.each(models, function (k, v) {
                            displacement_options += '<option value="' + v.id + '">' + v.reference + '</option>';
                        });
                        $contextParent.find('select.ucpt-search__select--reference').append(displacement_options);
                    });
                    $contextParent.find('select.ucpt-search__select--reference').prop('disabled', false).selectpicker('refresh');

                    $contextParent.removeClass('ucpt-spinner--is-spining');
                }
            });
        }
    });
    /* ajax script to get manufacturer-year data*/
    $(document).on('change', 'select.ucpt-search__select--type-year', function () {
        var $contextParent = $(this).parents('.ucpt-search')
        $contextParent.find('select.ucpt-search__select--manufacturer-year,select.ucpt-search__select--year,select.ucpt-search__select--model').html('').prop('disabled', true).selectpicker('refresh');

        var typeID = $(this).val();
        if (typeID) {
            currentRequest = $.ajax({
                type: 'GET',
                dataType: 'json',
                beforeSend: function () {
                    if (currentRequest != null) {
                        currentRequest.abort();
                        currentRequest = null;
                    }
                },
                url: '/module/ukooparts/front?action=getBrand&ajax=true&type=' + typeID,
                success: function (result) {

                    $contextParent.find('select.ucpt-search__select--manufacturer-year').empty();
                    var options_highlight = '<optgroup label="' + top_brands + '">';
                    var options_normal = '<optgroup label="' + our_other_brands + '">';
                    $.each(result.highlight, function (k, v) {
                        options_highlight += '<option value="' + v.id + '">' + v.name + '</option>'
                    });
                    $.each(result.normal, function (k, v) {
                        options_normal += '<option value="' + v.id + '">' + v.name + '</option>'
                    });
                    options_highlight += '</optgroup>';
                    options_normal += '</optgroup>';

                    $contextParent.find('select.ucpt-search__select--manufacturer-year').append(options_highlight, options_normal);
                    $contextParent.find('select.ucpt-search__select--manufacturer-year').prop('disabled', false).selectpicker('refresh');

                    $contextParent.removeClass('ucpt-spinner--is-spining');
                }
            });
        }
    });
    /* ajax script to get years data */
    $(document).on('change', 'select.ucpt-search__select--manufacturer-year', function () {
        var $contextParent = $(this).parents('.ucpt-search');
        $contextParent.find('select.ucpt-search__select--year,select.ucpt-search__select--model').html('').prop('disabled', true).selectpicker('refresh');

        var typeID = $contextParent.find('select.ucpt-search__select--type-year').val();
        console.log(typeID);
        var marqueID = $(this).val();
        if (marqueID) {
            currentRequest = $.ajax({
                type: 'GET',
                dataType: 'json',
                beforeSend: function () {
                    if (currentRequest != null) {
                        currentRequest.abort();
                        currentRequest = null;
                    }
                },
                url: '/module/ukooparts/front?action=getYears&ajax=true&type=' + typeID + '&brand=' + marqueID,
                success: function (result) {
                    $contextParent.find('select.ucpt-search__select--year').empty();
                    $.each(result, function (k, v) {
                        $contextParent.find('select.ucpt-search__select--year').append(" ", '<option value="' + v.year + '">' + v.year + '</option>');
                    });
                    $contextParent.find('select.ucpt-search__select--year').prop('disabled', false).selectpicker('refresh');

                    $contextParent.removeClass('ucpt-spinner--is-spining');
                }
            });
        }
    });

    /* ajax script to get model data */
    $(document).on('change', 'select.ucpt-search__select--year', function () {
        var $contextParent = $(this).parents('.ucpt-search');
        $contextParent.find('select.ucpt-search__select--model').html('').prop('disabled', true).selectpicker('refresh');

        var typeID = $contextParent.find('select.ucpt-search__select--type-year').val();
        var brandID = $contextParent.find('select.ucpt-search__select--manufacturer-year').val();
        var yearID = $(this).val();
        if (yearID) {
            currentRequest = $.ajax({
                type: 'GET',
                dataType: 'json',
                beforeSend: function () {
                    if (currentRequest != null) {
                        currentRequest.abort();
                        currentRequest = null;
                    }
                },
                url: '/module/ukooparts/front?action=getModels&ajax=true&type=' + typeID + '&brand=' + brandID + '&year=' + yearID,
                success: function (result) {
                    $contextParent.find('select.ucpt-search__select--model').empty();
                    $.each(result, function (displacement, models) {
                        var displacement_optgroup = '<optgroup label="' + displacement + '">';
                        $.each(models, function (k, v) {
                            displacement_optgroup += '<option value="' + v.id + '">' + v.model + '</option>';
                        });
                        displacement_optgroup += '</optgroup>';
                        $contextParent.find('select.ucpt-search__select--model').append(displacement_optgroup);
                    });
                    $contextParent.find('select.ucpt-search__select--model').prop('disabled', false).selectpicker('refresh');

                    $contextParent.removeClass('ucpt-spinner--is-spining');
                }
            });
        }
    });

    // == UX management (disabling, spinner)
    $('.ucpt-search .ucpt-search__select').on('change', function(){
        $('.ucpt-search .ucpt-search__submit').removeAttr('disabled');
    });

    $('.ucpt-search .ucpt-search__form').submit(function(){
        $(this).parents('.ucpt-search').addClass('ucpt-spinner--is-spining');
    });
});



