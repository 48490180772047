/**
 * Prestashop module : Ukooparts
 *
 * @author Ukoo <contact@ukoo.fr>
 * @copyright Ukoo
 * @license Tous droits réservés / Le droit d'auteur s'applique (All rights reserved / French copyright law applies)
 */

$(document).ready(function(){
    $('.ucpt-table form').submit(function(){
        $(this).parents('.ucpt-table__list').addClass('ucpt-spinner--is-spining');
    });
});
