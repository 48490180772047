$(document).ready(function () {
    holder = '.ucpt-holder';
    $holderInput = $('.ucpt-holder__input');

    holderFilledClass($holderInput);

    $holderInput.on('change', function () {
        holderFilledClass($(this));
    });

    function holderFilledClass(e) {
        if (!(e.val() === null || e.val() === '')) {
            e.parents(holder).addClass('ucpt-holder--filled')
        } else {
            e.parents(holder).removeClass('ucpt-holder--filled')
        }
    }
});